import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Card, Col, Form, Row } from "react-bootstrap";
import { TableRow, TableCell, IconButton } from "@mui/material";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";

import { UseSelectorI } from "interfaces";
import { AnanseApiService } from "app/services/Ananse/AnanseApiService";
import Loading from "app/pages/home/components/Loading";
import FormActions from "components/FormActions";
import { SelectPage } from "components";
import { Edit } from "@mui/icons-material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import TableWithAutoPagination from "./component/TableUser";
import DeleteQuiz from "./component/DeleteQuiz";
import { SelectFragrance } from "app/pages/admin/pages/Anamnese/components";
import { CollectionItem, IForm, IFormQuiz } from "types";
import type { IConvertData } from "components/Selects/SelectPage";
import { useTranslation } from "_metronic/i18n/language";

interface IConvertObject {
  id: number;
  value: number;
  label: string;
  obj: any;
}

interface Props {
  form: IForm;
  collection?: Array<CollectionItem>;
}
const FormQuiz: React.FC<Props> = ({ form, collection }) => {
  const params = useParams();
  const locale = useSelector(({ i18n }: UseSelectorI) => i18n.lang);
  const translate = useTranslation();
  const api = new AnanseApiService();
  const [loading, setLoading] = useState(false);
  const [quizSelected, setQuizSelected] = useState<IConvertObject | undefined>(
    {} as IConvertObject
  );
  const [formQuiz, setFormQuiz] = useState({} as IFormQuiz);
  const [dataFormQuiz, setDataFormQuiz] = useState<IFormQuiz[]>([]);
  const [quiz, setQuiz] = useState([]);
  const [validateForm, setValidateForm] = useState(false);
  const [formSelected, setFormSelected] = useState({} as any);
  const [fragranceId, setFragranceId] = useState<number>();

  const schema = Yup.object().shape({
    sequenceNo: Yup.number()
      .required(translate('screens.login.validations.required'))
      .min(1, translate('screens.login.validations.minValue')),
    quizId: Yup.number()
      .required(translate('screens.login.validations.required'))
      .min(1, translate('screens.login.validations.minValue'))
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    reset,
    control
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      formId: form.formId,
      sequenceNo: 0,
      quizId: 0,
      fragranceId
    }
  });

  useEffect(() => {
    loadingData();
  }, []);

  const onSubmit = async (data: any) => {
    const payload = {
      ...data,
      quizId: quizSelected?.id,
      sequenceNo: Number(data.sequenceNo),
      formId: form.formId,
      fragranceId: fragranceId
    };
    
    const isDuplicate = dataFormQuiz.some(
      (quiz) =>
        quiz.sequenceNo === payload.sequenceNo &&
      quiz.quizId === payload.quizId &&
      quiz.fragranceId === payload.fragranceId
    );
    
    if (isDuplicate) {
      Swal.fire({
        title: translate('screenApp_capsula_error'),
        text: translate('labels_streams_combinationRegistered'),
        icon: "error"
      });
      return;
    }
    
    const isDuplicateSequence = dataFormQuiz.some(
      (quiz) => quiz.sequenceNo === payload.sequenceNo
    );
    
    if (isDuplicateSequence) {
      Swal.fire({
        title: translate('screenApp_capsula_error'),
        text: translate('labels_streams_sequenceInUse'),
        icon: "error"
      });
      return;
    }
    
    const isDuplicateFragrance = dataFormQuiz.some(
      (quiz) => quiz.fragranceId === payload.fragranceId
    );
    
    if (isDuplicateFragrance) {
      Swal.fire({
        title: translate('screenApp_capsula_error'),
        text: translate('labels_streams_fragranceAssociated'),
        icon: "error"
      });
      return;
    }
    
    setLoading(true);
    try {
      const { id }: any = params;
      let reqBase: any;
      if (data.formQuizId) {
        reqBase = { method: "PUT", url: `/formquiz/${data.formQuizId}` };
      } else {
        reqBase = { method: "POST", url: "/formquiz" };
      }

      const response = await api.makeHttpRequest({
        ...reqBase,
        data: payload
      });
      setFormQuiz(response);
      Swal.fire({
        title: translate('defaultMessages.success'),
        text: translate('crudMessages.successText'),
        icon: "success"
      }).then(result => {
        if (result.isConfirmed) {
          loadingData();
        }
      });
    } catch (e) {
      console.error(e);
      Swal.fire(
        translate('defaultMessages.error'),
        translate('screens.user.errors.register'),
        "error"
      );
    } finally {
      setLoading(false);
      handleReset();
      setQuizSelected({} as any)
      setValue('sequenceNo', 0)
      setValue('quizId', 0)
      setFragranceId(0)
    }
  };

  useEffect(() => {
    setFormSelected(form);
  }, [form]);

  const loadingData = async () => {
    setLoading(true);

    try {
      loadAllQuizes();
      if (form.formId) {
        const { total } = await api.makeHttpRequest({
          method: "GET",
          url: "/formquiz"
        });

        const response = await api.makeHttpRequest({
          method: "GET",
          url: `/formquiz?PerPage=${total}`
        });
        const newData = response.data;
        const filterFormQuiz = newData
          .filter((v: any) => v.formId === form.formId)
          .sort((a: any, b: any) => a.sequenceNo - b.sequenceNo);
        setDataFormQuiz(filterFormQuiz);
      }
    } catch (e) {
      console.error(e);
      Swal.fire(
        translate('defaultMessages.error'),
        translate('screens.user.errors.register'),
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const loadAllQuizes = async () => {
    try {
      const { total } = await api.makeHttpRequest({
        method: "GET",
        url: "/quiz"
      });
      const { data } = await api.makeHttpRequest({
        method: "GET",
        url: `/quiz?PerPage=${total}`
      });
      setQuiz(data);
    } catch (error) { }
  };

  const searchQuizById = (id: number) => {
    const filteNameById: any = quiz.find((v: any) => v.quizId === id);
    return filteNameById;
  };

  const handleReset = () => {
    reset({});
    setValidateForm(false);
  };

  const onEdit = (value: IFormQuiz) => {
    reset({
      ...value
    });
    const dataQ: IConvertObject = {
      label: "conf",
      id: value.quizId,
      value: value.quizId,
      obj: {}
    };
    setQuizSelected(dataQ);
    setValidateForm(true);
    setFragranceId(value?.fragranceId || undefined);
  };

  const formatHead = [
    {
      column: "acoes", label: translate('screens.dashboard.action')
    },
    {
      column: "nome",
      label: translate('screens.quiz.list.sequence'),
      rest: { align: "right" }
    },
    {
      column: "nome",
      label: translate('screens.quiz.form')
    },
    {
      column: "nome",
      label: translate('screenApp.fragrance')
    }
  ];

  const formatRow = (row: IFormQuiz) => {
    const fragrance = collection?.find(
      coll => coll.fragranceId === row.fragranceId
    );
    return (
      <TableRow key={row.formQuizId}>
        <TableCell>
          <IconButton>
            <DeleteQuiz item={row} loadingData={loadingData} />
          </IconButton>
          <IconButton onClick={() => onEdit(row)}>
            <Edit />
          </IconButton>
        </TableCell>
        <TableCell align="right">{row.sequenceNo}</TableCell>
        <TableCell>{searchQuizById(row.quizId)?.name}</TableCell>
        <TableCell>{fragrance?.fragrance?.name || "-"}</TableCell>
      </TableRow>
    );
  };

  const validateDataSelected = (
    e: IConvertData | undefined,
    onChange: (e: any) => void
  ) => {
    setQuizSelected(e);
    onChange(e?.id);
    setValidateForm(true);
    setFragranceId(e?.obj?.fragranceId);
    return true;
  };

  return (
    <>
      <TableWithAutoPagination
        dataFormUser={dataFormQuiz}
        formatRow={formatRow}
        formatHead={formatHead}
      />
      <Grid2 md={12} lg={7}>
        <Card>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Card.Header>
              {translate('screens.quiz.list.sequence')} - "{form.formName}"
            </Card.Header>
            <Card.Body>
              <Row>
                <Form.Group as={Col} lg="12" xs="12">
                  <Form.Label>
                    {translate('labels.numberSequence')} *
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={translate('screens.user.placeholders.name')}
                    {...register("sequenceNo")}
                  />
                  {errors.sequenceNo && (
                    <span className="invalid-input">
                      {errors.sequenceNo.message}
                    </span>
                  )}
                </Form.Group>
                <Form.Group as={Col} lg="12" xs="12">
                  <Form.Label>
                    {translate('screens.quiz.form')}
                  </Form.Label>
                  <Controller
                    name="quizId"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <SelectPage
                        endPoint={`/quiz/getcampagnandtype/2?campagnId=${formSelected?.campaignId}`}
                        notPerPage
                        label={
                          translate('screens.fragrance.placeholders.selectForm')
                        }
                        value={value}
                        convertObject={(obj: any) => ({
                          id: obj.quizId,
                          label: obj.name,
                          value: obj.quizId,
                          obj
                        })}
                        handleChange={e => {
                          validateDataSelected(e, onChange);
                        }}
                      />
                    )}
                  />
                  {errors.quizId && (
                    <span className="invalid-input">
                      {errors.quizId.message}
                    </span>
                  )}
                </Form.Group>
              </Row>
              {collection && Object.keys(collection).length > 0 && (
                <Form.Group as={Col} lg="4" xs="12" className="mb-0 pt-2">
                  <Form.Label>
                    {translate('screens.quiz.labels.fragrances')}
                  </Form.Label>
                  <SelectFragrance
                    collection={collection}
                    onFragranceSelected={fragrance => {
                      const e = {
                        id: fragrance?.fragranceId,
                        value: fragrance?.fragranceId,
                        label: fragrance?.name
                      };
                      setFragranceId(e?.id);
                      setValue("fragranceId", e?.id);
                    }}
                    fragranceId={fragranceId}
                    disabled={!collection.length || !fragranceId}
                  />
                </Form.Group>
              )}
            </Card.Body>
            <FormActions
              module="flow"
              onReset={handleReset}
              disableSubmit={!validateForm}
            />
          </Form>
        </Card>
      </Grid2>
      <Loading isLoading={loading} />
    </>
  );
};

export default FormQuiz;
